'use client'

import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { getCookieAsParsedJson, setCookie } from '~/utils/cookies'

import { Button } from '~/components/Button/Button'

import styles from './AdultModal.module.css'

type Props = {
  title: string
  acceptButtonTitle: string
  rejectButtonTitle: string
  rejectButtonRedirectURL?: string
  disclaimer: string
  theme?: typeof styles
}

export const AdultModal = ({ title, acceptButtonTitle, rejectButtonTitle, rejectButtonRedirectURL = '/', disclaimer, theme }: Props) => {
  const mergedTheme = { ...styles, ...theme }

  const [isOpen, setIsOpen] = useState(false)

  useLayoutEffect(() => {
    if (!getCookieAsParsedJson('adult')) {
      setIsOpen(true)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  const confirmAgeAndClose = useCallback(() => {
    setCookie('adult', true)
    setIsOpen(false)
  }, [])

  return (
    <div className={`${mergedTheme.container} ${!isOpen ? mergedTheme.hidden : ''}`}>
      <div role='dialog' id='adult-dialog' aria-labelledby='adultDialogLabel' aria-modal='true' className={mergedTheme.modal}>
        <h1 className={`heading1 ${mergedTheme.title}`} id='adultDialogLabel'>
          {title}
        </h1>
        <Button onClick={confirmAgeAndClose} className={`${mergedTheme.button} ${mergedTheme.acceptButton}`} size='small'>
          {acceptButtonTitle}
        </Button>
        <Button href={rejectButtonRedirectURL} className={`${mergedTheme.button} ${mergedTheme.rejectButton}`} size='small' variant='ghost'>
          {rejectButtonTitle}
        </Button>
        <p>{disclaimer}</p>
      </div>
    </div>
  )
}
