'use client'
import React, { useEffect, useRef } from 'react'

import useYouTubeAPI from '~/utils/useYoutubeApi'

export interface YouTubeEmbedProps {
  youtubeId: string
  startTime?: number
  endTime?: number
  height?: number
  width?: number
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ youtubeId, startTime = 0, endTime = 0, height = 390, width = 640 }) => {
  const playerRef = useRef<HTMLDivElement>(null)
  const apiReady = useYouTubeAPI()

  useEffect(() => {
    const createPlayer = () => {
      if (!playerRef.current || !apiReady) return

      const player = new window.YT.Player(playerRef.current, {
        height,
        width,
        videoId: youtubeId,
        playerVars: {
          start: startTime,
          end: endTime,
          autoplay: 0,
        },
        events: {
          onReady: (event: any) => {
            event.target.cueVideoById({
              videoId: youtubeId,
              startSeconds: startTime,
              endSeconds: endTime,
            })
          },
          onStateChange: (event: any) => {
            if (event.data === window.YT.PlayerState.PLAYING) {
              const intervalId = setInterval(() => {
                const currentTime = event.target.getCurrentTime()
                if (currentTime >= endTime && endTime !== 0) {
                  event.target.pauseVideo()
                  clearInterval(intervalId)
                }
              }, 1000)
            }
          },
        },
      })

      return () => {
        player.destroy()
      }
    }

    if (apiReady) {
      createPlayer()
    }
  }, [apiReady, youtubeId, startTime, endTime])

  return <div ref={playerRef}></div>
}

export default YouTubeEmbed
