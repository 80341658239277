import(/* webpackMode: "eager" */ "/app/components/AdultModal/AdultModal.tsx");
import(/* webpackMode: "eager" */ "/app/components/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/components/Footer/FooterBF.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/FooterCHM.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/Footer.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/FooterBW.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/FooterCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/Footer/FooterPL.module.css");
import(/* webpackMode: "eager" */ "/app/components/AdultModal/AdultModal.module.css");
import(/* webpackMode: "eager" */ "/app/components/AdultModal/AdultModalRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/Header/Header.module.css");
import(/* webpackMode: "eager" */ "/app/components/Button/Button.module.css");
import(/* webpackMode: "eager" */ "/app/components/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/app/components/Header/HeaderCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/Header/HeaderBW.module.css");
import(/* webpackMode: "eager" */ "/app/components/Header/HeaderRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/CollectionSection/CollectionSectionPL.module.css");
import(/* webpackMode: "eager" */ "/app/components/HeroSection/heroSectionBW.module.css");
import(/* webpackMode: "eager" */ "/app/components/LocationCollectionSection/LocationCollectionSectionView.tsx");
import(/* webpackMode: "eager" */ "/app/components/LocationMenu/LocationMenuView.tsx");
import(/* webpackMode: "eager" */ "/app/components/LogosSection/LogosSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/LogosSection/LogosSectionRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/CollectionSection/CollectionSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/HeroSection/HeroSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/PhoneSection/PhoneSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/PhoneSection/PhoneSectionRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/ReservationOfferCard/ReservationOfferCard.tsx");
import(/* webpackMode: "eager" */ "/app/components/Slider/Slider.tsx");
import(/* webpackMode: "eager" */ "/app/components/StatisticsSection/StatisticsSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/StatisticsSection/StatisticsSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/StatisticsSection/StatisticsSectionRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/WeOfferSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/WeOfferSectionCHM.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/WeOfferSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/WeOfferSectionRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/breakfastweek/WeOfferSectionBFHowWorks.module.css");
import(/* webpackMode: "eager" */ "/app/components/WeOfferSection/breakfastweek/WeOfferSectionBF.module.css");
import(/* webpackMode: "eager" */ "/app/components/LogosSection/LogosSectionCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/CollectionSection/CollectionSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/ReservationOfferCard/ReservationOfferCard.module.css");
import(/* webpackMode: "eager" */ "/app/components/ReservationOfferCard/ReservationOfferCardCZ.module.css");
import(/* webpackMode: "eager" */ "/app/components/HeroSection/HeroSection.module.css");
import(/* webpackMode: "eager" */ "/app/components/HeroSection/HeroSectionRWPL.module.css");
import(/* webpackMode: "eager" */ "/app/components/PhoneSection/PhoneSectionBW.module.css");
import(/* webpackMode: "eager" */ "/app/components/YoutubeEmbed/YoutubeEmbedView.tsx");
import(/* webpackMode: "eager" */ "/app/components/YoutubeSection/YoutubeSectionRW.module.css");
import(/* webpackMode: "eager" */ "/app/components/YoutubeSection/YoutubeSection.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@restaurantclub/svg-client-icons/dist/sprite.svg");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-alice-carousel/lib/alice-carousel.css");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/[lang]/pl/restaurantweek/page.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-poppins\",\"subsets\":[\"latin-ext\"]}],\"variableName\":\"poppins\"}")