import { useEffect, useState } from 'react'

const useYouTubeAPI = () => {
  const [apiReady, setApiReady] = useState(false)

  useEffect(() => {
    const loadYouTubeIframeAPI = () => {
      return new Promise<void>(resolve => {
        if (window.YT) {
          resolve()
        } else {
          const tag = document.createElement('script')
          tag.src = 'https://www.youtube.com/iframe_api'
          const firstScriptTag = document.getElementsByTagName('script')[0]
          firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)

          window.onYouTubeIframeAPIReady = () => {
            resolve()
          }
        }
      })
    }

    const initializeAPI = async () => {
      try {
        await loadYouTubeIframeAPI()
        setApiReady(true)
      } catch {
        setApiReady(false)
      }
    }

    initializeAPI()
  }, [])

  return apiReady
}

export default useYouTubeAPI
